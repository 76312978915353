import React from "react";

type Props = {};

const Header = (props: Props) => {
  return (
    <header className="text-gray-600">
      <div className="bg-primary">
        <div className="container mx-auto px-4 py-3 flex items-center justify-between">
          <a href="/" className="flex title-font font-medium items-center text-gray-900">
            <img className="h-12 w-12" src="/images/logo-white.png" alt="" />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
